import React, { useState, useEffect, useContext } from "react";
import NavBar from "../components/Nav";
import Footer from "../components/Footer";
import { Store } from "../context/store";
import { useParams } from "react-router-dom";
import LectureComp from "../components/singleLecture";
import GoToTop from "../components/Top";
import { MoonLoader } from "react-spinners";

function Lecture() {
  let store = useContext(Store);
  let [mainUrl] = store.url;
  let [lecture, setLecture] = useState({});
  let [videoId, setVideoId] = useState("");
  let { id } = useParams();

  useEffect(() => {
    loadLecture();
  }, []);

  let loadLecture = () => {
    let url = mainUrl + "/lecture/" + id;
    fetch(url)
      .then((e) => e.json())
      .then((res) => {
        setLecture(res);
        const videoId = res.url.split("v=")[1];
        const ampersandPosition = videoId.indexOf("&");
        if (ampersandPosition !== -1) {
          return videoId.substring(0, ampersandPosition);
        }
        return setVideoId(videoId);
      });
  };

  return (
    <>
      <NavBar />

      <div className="container-fluid" style={{ marginTop: "100px" }}>
        <div className="container mt-5">
          <div className="row pt-4" style={{ background: "whitesmoke" }}>
            {lecture ? (
              <>
                <div className="col-12">
                  <LectureComp
                    image={lecture.image}
                    title={lecture.title}
                    scholar={lecture.scholar}
                    type={lecture.type}
                    url={lecture.url}
                    name={lecture.user_id?.name}
                    videoId={videoId}
                    category={lecture.category?.title}
                  />
                </div>
              </>
            ) : (
              <MoonLoader
                height="100"
                width="100"
                radius="50"
                color="green"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
              />
            )}
          </div>
        </div>
        <GoToTop />
      </div>
      <Footer />
    </>
  );
}

export default Lecture;
