import React from "react";
import { Link } from "react-router-dom";

function List(props) {
    return <Link to={`/lecture/${props.id}`} >
        <div className="list" >
            <h5>{props.title}</h5>
            <div className="list-btw">
                <small className="card-btw">{props.scholar}</small>
                <small>{props.time}</small>
            </div>
        </div>
    </Link>
}
export default List;