import React, { useState, useEffect, useContext } from "react";
import NavBar from "../components/Nav";
import Footer from "../components/Footer";
import Card from "../components/card";
import List from "../components/list";
import { Store } from "../context/store";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import GoToTop from "../components/Top";
import AudioPlay from "../components/listPlay";
import { MoonLoader } from "react-spinners";

function Home() {
  let store = useContext(Store);
  let [mainUrl] = store.url;
  let [lectures, setLectures] = useState([]);
  let [lecture, setLecture] = useState([]);
  let [category, setCategory] = useState([]);
  let [, setCatFilter] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    loadLectures();
    loadCategory();
    loadAudioLectures();
  }, []);

  let loadLectures = () => {
    let url = mainUrl + "/lectures/video-8";
    fetch(url)
      .then((e) => e.json())
      .then((res) => {
        setLectures(res);
      });
  };

  let loadAudioLectures = () => {
    let url = mainUrl + "/lectures/audio-5";
    fetch(url)
      .then((e) => e.json())
      .then((res) => {
        setLecture(res);
      });
  };

  let loadCategory = () => {
    let url = mainUrl + "/category";
    fetch(url)
      .then((e) => e.json())
      .then((res) => {
        setCategory(res);
      });
  };

  const handleSearch = (e) => {
    const q = e.target.value;
    setSearchQuery(q);
    const filteredResults = lectures.filter((item) =>
      item.title.toLowerCase().includes(q.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  return (
    <>
      <NavBar />

      <div className="container-fluid" style={{ marginTop: "100px" }}>
        <div className="container mt-5">
          <Row className="my-2 pt-4 pb-3 px-3 lectureTopBar justify-content-between">
            <Col className="lectureInput">
              <select
                onChange={(e) => setCatFilter(e.target.value)}
                style={{ textTransform: "capitalize" }}
              >
                <option value={""} className="selectOption">
                  All Categories
                </option>
                {category.map((e, i) => {
                  return (
                    <option value={e._id} key={i} className="selectOption">
                      {e.title.toUpperCase()}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col className="lectureInput" lg="8">
              <input
                type="search"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearch}
              />
            </Col>
          </Row>

          {searchResults.length > 0 && searchQuery !== "" && (
            <div className="mt-1 p-2 search">
              {searchResults.map((e, i) => {
                return (
                  <Link to={`/lecture/${e._id}`}>
                    <p key={i}>{e.title}</p>
                  </Link>
                );
              })}
            </div>
          )}

          <div className="row pt-4" style={{ background: "whitesmoke" }}>
            {lectures.length > 0 || lecture.length > 0 ? (
              <>
                {/* <div className="col-lg-8">
                  <div className="row">
                    {lectures?.length === 0 ? (
                      <div className="loader">
                        <MoonLoader
                          height="100"
                          width="100"
                          radius="50"
                          color="green"
                          ariaLabel="loading"
                          wrapperStyle
                          wrapperClass
                        />
                      </div>
                    ) : (
                      lectures?.map((e, i) => {
                        return (
                          <div className="col-md-6" key={i}>
                            <Card
                              location={e.location}
                              image={e.image}
                              title={e.title}
                              scholar={e.scholar}
                              type={e.type}
                              id={e._id}
                              category={e.category?.title}
                            />
                          </div>
                        );
                      })
                    )}
                  </div>
                  <div className="view d-flex justify-content-center">
                    <Link to={"/lectures/video"}>
                      <h6>VIEW MORE</h6>
                    </Link>
                  </div>
                </div> */}

                <div className="col-lg-8">
                  <div className="row">
                    {lectures?.length === 0 ? (
                      <div className="loader">
                        <MoonLoader
                          height="100"
                          width="100"
                          radius="50"
                          color="green"
                          ariaLabel="loading"
                          wrapperStyle
                          wrapperClass
                        />
                      </div>
                    ) : (
                      <>
                        {lectures?.map((e, i) => {
                          return (
                            <div className="col-md-6" key={i}>
                              <Card
                                location={e.location}
                                image={e.image}
                                title={e.title}
                                scholar={e.scholar}
                                type={e.type}
                                id={e._id}
                                category={e.category?.title}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                    <div className="view d-flex justify-content-center">
                      <Link to={"/lectures/video"}>
                        <h6>VIEW MORE</h6>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 card">
                  <div className="row">
                    <div className="col-lg-12">
                      <h5 id="latestH5">Latest Videos</h5>
                      {lectures?.map((e, i) => {
                        const timestamp = e.createdAt;
                        const uploadTime = new Date(timestamp);
                        const currentTime = new Date();
                        let time = "";
                        const timeDifferenceInMilliseconds =
                          currentTime - uploadTime;
                        const minutesAgo = Math.floor(
                          timeDifferenceInMilliseconds / (1000 * 60)
                        );
                        const hoursAgo = Math.floor(minutesAgo / 60);
                        const daysAgo = Math.floor(hoursAgo / 24);

                        if (daysAgo >= 1) {
                          time = `${daysAgo}d ago`;
                        } else if (hoursAgo >= 1) {
                          time = `${hoursAgo}hr ago`;
                        } else {
                          time = `${minutesAgo}min ago`;
                        }
                        if (i < 5)
                          return (
                            <List
                              key={e._id}
                              time={time}
                              title={e.title}
                              scholar={e.scholar}
                              id={e._id}
                            />
                          );
                      })}
                    </div>

                    <div className="col-lg-12">
                      <h5 id="latestH5">Latest Audio</h5>
                      {lecture?.map((e, i) => {
                        const timestamp = e.createdAt;
                        const uploadTime = new Date(timestamp);
                        const currentTime = new Date();
                        let time = "";
                        const timeDifferenceInMilliseconds =
                          currentTime - uploadTime;
                        const minutesAgo = Math.floor(
                          timeDifferenceInMilliseconds / (1000 * 60)
                        );
                        const hoursAgo = Math.floor(minutesAgo / 60);
                        const daysAgo = Math.floor(hoursAgo / 24);

                        if (daysAgo >= 1) {
                          time = `${daysAgo}d ago`;
                        } else if (hoursAgo >= 1) {
                          time = `${hoursAgo}hr ago`;
                        } else {
                          time = `${minutesAgo}min ago`;
                        }
                        if (i < 5)
                          return (
                            <AudioPlay
                              key={e._id}
                              time={time}
                              title={e.title}
                              scholar={e.scholar}
                              id={e._id}
                            />
                          );
                      })}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <MoonLoader
                height="100"
                width="100"
                radius="50"
                color="green"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
              />
            )}
          </div>
          <GoToTop />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
