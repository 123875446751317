import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import StoreContext from "./context/store";
import "./assets/css/style.css";
import "./assets/css/card.css";
import "./assets/css/list.css";
import "./assets/css/filter.css";
import "./assets/css/form.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/bootstrap.css";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Lecture from "./pages/Lecture";
import AllLectures from "./pages/AllLectures";
import Contact from "./pages/Contact";
import AudioLecture from "./pages/Audio";
import Gallery from "./pages/Gallery";
import LectureAudio from "./pages/LectureAudio";
import Login from "./pages/Login";
import Upload from "./pages/Upload";
import Register from "./pages/Register";
import { useCookies } from "react-cookie";

function App() {
  const [cookies] = useCookies(["abu-refresh"]);

  const UploadRouteGuard = ({ element }) => {
    if (!cookies["abu-refresh"]) {
      return <Navigate to="/" />;
    }
    return element;
  };

  return (
    <StoreContext>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lectures/video" element={<AllLectures />} />
          <Route path="/lectures/audio" element={<AudioLecture />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/lecture/:id" element={<Lecture />} />
          <Route path="/lecture/audio/:id" element={<LectureAudio />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/upload"
            element={<UploadRouteGuard element={<Upload />} />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </StoreContext>
  );
}

export default App;
