// import React from "react";
// import Container from 'react-bootstrap/Container';
// import { Col, Row } from "react-bootstrap";
// import YouTube from 'react-youtube';

// function LectureComp(props) {
//     return <>
//         <Container>
//             <Row className="mb-4 g-5">
//                 <Col className="lecture-details p-3" lg="12" >
//                     <h2>{props.title}</h2>

//                     <hr />

//                     <span className="">
//                         <div className="carousell" style={{ textAlign: 'center' }}>
//                             <YouTube videoId={props.videoId} height='300' width='500' autoplay={1} />;
//                         </div>
//                     </span>

//                     <hr />

//                 </Col>
//             </Row>
//         </Container>
//     </>
// }

// export default LectureComp;

import React from "react";
import Container from 'react-bootstrap/Container';
import { Col, Row } from "react-bootstrap";
import YouTube from 'react-youtube';

function LectureComp(props) {
    const opts = {
        height: '500', // Set the desired height of the YouTube video
        width: '100%', // Set the width to cover the entire container
        playerVars: {
            autoplay: 1,
        },
    };

    return (
        <Container>
            <Row className="mb-4 g-5">
                <Col className="lecture-details p-3" lg="12" >
                    <h2>{props.title}</h2>

                    <hr />

                    <div className="carousell" style={{ textAlign: 'center' }}>
                        <YouTube videoId={props.videoId} opts={opts} />;
                    </div>

                    <hr />
                    <div className="mt-3">
                        <small>Category: {props.category}</small> <br />
                        <small>Scholar: {props.scholar}</small>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default LectureComp;
