import React, { useState } from 'react';

// Modal component
function ImageModal({ imageUrl, onClose }) {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <img src={imageUrl} alt="Image" />
      </div>
    </div>
  );
};

export default ImageModal;