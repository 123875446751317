import React, { useState } from "react";
import ImageModal from "./imageModal";

function ImageCard(props) {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="card card-body zoom">
      {/* <div className=" card-img">
        <img
          src={props.image}
          alt="post"
          className="img-circle img-responsive"
          onClick={openModal}
        />
      </div> */}

      <div className="card-img" onClick={openModal}>
        <img src={props.image} alt="post" className="img-circle img-responsive" />
      </div>

      {modalVisible && (
        <ImageModal imageUrl={props.image} onClose={closeModal} />
      )}
    </div>
  );
}
export default ImageCard;
