import React, { useState, useEffect, useContext } from "react";
import NavBar from "../components/Nav";
import Footer from "../components/Footer";
import { Store } from "../context/store";
import { useParams } from "react-router-dom";
import GoToTop from "../components/Top";
import { Icon } from '@iconify/react';

function LectureAudio(props) {
    let store = useContext(Store);
    let [mainUrl] = store.url;
    let [lecture, setLecture] = useState({});
    let { id } = useParams();
    let [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        loadLecture();
    }, []);

    let loadLecture = () => {
        let url = mainUrl + '/lecture/' + id;
        fetch(url)
            .then((response) => response.json())
            .then((res) => {
                setLecture(res);
                setLoading(false); // Set loading to false after fetching data
            })
            .catch((error) => {
                console.error("Error fetching lecture:", error);
                setLoading(false);
            });
    };

    const getTimeAgo = (timestamp) => {
        // Calculate time difference as before...
        // Return formatted time ago string
        timestamp = lecture?.createdAt;
        const uploadTime = new Date(timestamp);
        const currentTime = new Date();
        let time = ""
        const timeDifferenceInMilliseconds = currentTime - uploadTime;
        const minutesAgo = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
        const hoursAgo = Math.floor(minutesAgo / 60);
        const daysAgo = Math.floor(hoursAgo / 24);

        if (daysAgo >= 1) {
            return `${daysAgo}d ago`;
        } else if (hoursAgo >= 1) {
            return `${hoursAgo}hr ago`;
        } else {
            return`${minutesAgo}min ago`;
        }
    };



    return <>
        <NavBar />

        <div className="container-fluid" style={{ marginTop: "100px" }}>
            <div className="container mt-5">
                <div className="row pt-4" style={{ background: "whitesmoke" }}>
                    <div className="col-12 p-3">
                        <div id="play">
                            <div>
                                {loading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <div id="playy">
                                        <h5 className="mb-3 text-center">{lecture.title}</h5>
                                        <div className="d-flex justify-content-center">
                                            <audio controls controlsList="nodownload">
                                                <source
                                                    src={lecture.url}
                                                    type="audio/mpeg"
                                                />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div>
                                        <div className="d-flex justify-content-center my-2">
                                            <a
                                                rel="nofollow"
                                                href={lecture.url}
                                                download
                                                className="audio-download-link"
                                            >
                                                <Icon className="icon" icon="ic:round-download" width="30" height="30" />
                                            </a>
                                        </div>
                                        <div className="text-center">
                                            <small>Scholar: {lecture.scholar}</small>
                                            <br />
                                            <br />
                                            <small>{getTimeAgo(lecture.createdAt)}</small>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GoToTop />
        </div>
        <Footer />

    </>
}

export default LectureAudio;