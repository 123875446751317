import React from "react";
import { Link } from "react-router-dom";

function AudioPlay(props) {
    return (
        <Link to={`/lecture/audio/${props.id}`} >
            <div>
                <div
                    id="play"
                    className={`list`} 
                >
                    <div className="card-img mb-5">
                        {/* <p>{props.type}</p> */}
                        <h5 className="mb-3">{props.title}</h5>
                    </div>
                    <div className="list-btw">
                        <small className="card-btw">{props.scholar}</small>
                        <small>{props.time}</small>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default AudioPlay;