import React, { useState, useEffect, useContext } from "react";
import NavBar from "../components/Nav";
import Footer from "../components/Footer";
import { Store } from "../context/store";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import AudioPlay from "../components/listPlay";
import GoToTop from "../components/Top";
import { MoonLoader } from "react-spinners";

function AudioLecture() {
  let store = useContext(Store);
  let [mainUrl] = store.url;
  let [lectures, setLectures] = useState([]);
  let [category, setCategory] = useState([]);
  let [, setCatFilter] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [currentAudio, setCurrentAudio] = useState(null);

  useEffect(() => {
    loadLectures();
    loadCategory();
  }, []);

  let loadLectures = () => {
    let url = mainUrl + "/lectures/audio";
    fetch(url)
      .then((e) => e.json())
      .then((res) => {
        setLectures(res);
      });
  };

  let loadCategory = () => {
    let url = mainUrl + "/category";
    fetch(url)
      .then((e) => e.json())
      .then((res) => {
        setCategory(res);
      });
  };

  const handleSearch = (e) => {
    const q = e.target.value;
    setSearchQuery(q);
    const filteredResults = lectures.filter((item) =>
      item.title.toLowerCase().includes(q.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  // Play or pause audio based on current state
  const playOrPauseAudio = (audioId) => {
    if (currentAudio === audioId) {
      setCurrentAudio(null);
    } else {
      setCurrentAudio(audioId);
    }
  };

  return (
    <>
      <NavBar />

      <div className="container-fluid" style={{ marginTop: "100px" }}>
        <div className="container mt-5">
          <Row className="my-2 pt-4 pb-3 px-3 lectureTopBar justify-content-between">
            <Col className="lectureInput">
              <select
                onChange={(e) => setCatFilter(e.target.value)}
                style={{ textTransform: "capitalize" }}
                defaultValue=""
              >
                <option value={""} className="selectOption">
                  All Categories
                </option>
                {category.map((e, i) => {
                  return (
                    <option value={e.title} key={i} className="selectOption">
                      {e.title.toUpperCase()}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col className="lectureInput" lg="8">
              <input
                type="search"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearch}
              />
            </Col>
          </Row>

          {searchResults.length > 0 && searchQuery !== "" && (
            <div className="mt-1 p-2 search">
              {searchResults.map((e, i) => {
                return (
                  <Link to={`/lecture/audio/${e._id}`}>
                    <p key={i}>{e.title}</p>
                  </Link>
                );
              })}
            </div>
          )}

          <div className="row pt-4" style={{ background: "whitesmoke" }}>
            {lectures.length > 0 ? (
              <>
                <div className="col-lg-12 card">
                  <h3 className="text-center m-3">Audio Lectures</h3>
                  <div className="row">
                    {lectures?.map((e, i) => {
                      // Determine if this audio is currently playing
                      const isPlaying = currentAudio === e._id;
                      const timestamp = e.createdAt;
                      const uploadTime = new Date(timestamp);
                      const currentTime = new Date();
                      let time = "";
                      const timeDifferenceInMilliseconds =
                        currentTime - uploadTime;
                      const minutesAgo = Math.floor(
                        timeDifferenceInMilliseconds / (1000 * 60)
                      );
                      const hoursAgo = Math.floor(minutesAgo / 60);
                      const daysAgo = Math.floor(hoursAgo / 24);

                      if (daysAgo >= 1) {
                        time = `${daysAgo}d ago`;
                      } else if (hoursAgo >= 1) {
                        time = `${hoursAgo}hr ago`;
                      } else {
                        time = `${minutesAgo}min ago`;
                      }
                      return (
                        <div className="col-lg-4">
                          <AudioPlay
                            type={e.type}
                            time={time}
                            title={e.title}
                            scholar={e.scholar}
                            id={e._id}
                            isPlaying={isPlaying} // Pass the current playing state
                            playOrPauseAudio={() => playOrPauseAudio(e._id)} // Pass the play/pause function
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <MoonLoader
              height="100"
              width="100"
              radius="50"
              color="green"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
            )}
          </div>
          <GoToTop />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AudioLecture;
