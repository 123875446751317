import React, { useState, useEffect, useContext } from "react";
import NavBar from "../components/Nav";
import Footer from "../components/Footer";
import { Store } from "../context/store";
import ImageCard from "../components/imageCard";
import GoToTop from "../components/Top";
import { MoonLoader } from "react-spinners";

function Gallery() {
  let store = useContext(Store);
  let [mainUrl] = store.url;
  let [images, setImages] = useState([]);
  
  useEffect(() => {
    loadImages();
  }, []);

  let loadImages = () => {
    let url = mainUrl + "/gallery";
    fetch(url)
      .then((e) => e.json())
      .then((res) => {
        setImages(res);
      });
  };

  return (
    <>
      <NavBar />

      <div className="container-fluid" style={{ marginTop: "100px" }}>
        <div className="container mt-5">
          <div className="row pt-4" style={{ background: "whitesmoke" }}>
            {images.length > 0 ? (
              <>
                <div className="col-lg">
                  <div className="row">
                    <h3 className="text-center m-3">Gallery</h3>
                    {/* {images?.map((e, i) => {
                      return (
                        <div className="col-md-4" key={e._id}>
                          <ImageCard image={e.url}  />
                        </div>
                      );
                    })} */}
                    {/* {images?.map((e, i) => {
                      return (
                        <div className="col-md-4" key={e._id}>
                          <ImageCard image={e.url} />
                        </div>
                      );
                    })} */}
                    <div className="row">
                      {images?.map((e, i) => {
                        return (
                          <div className="col-md-4" key={e._id}>
                            <ImageCard image={e.url} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <MoonLoader
                height="100"
                width="100"
                radius="50"
                color="green"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
              />
            )}
          </div>
        </div>
        <GoToTop />
      </div>
      <Footer />
    </>
  );
}

export default Gallery;
