import React from "react";
import { Col, Row } from "react-bootstrap";
import { Icon } from '@iconify/react';
import Footer from "../components/Footer";
import NavBar from "../components/Nav";
import GoToTop from "../components/Top";

function Contact() {

    return <>
        <NavBar />

        <div className="container-fluid" style={{ marginTop: "100px" }}>
            <div className="container mt-5" style={{ background: "whitesmoke" }}>
                <Row className="my-4">


                    <Col className="reg-right-col text-center py-3" lg=''>
                        <Row>
                            <Col className="m-3 px-2 pb-3" lg='11' md="3">
                                <Icon style={{ borderRadius: '5px', background: '#000' }} icon="fluent:location-48-filled" color="white" width="56" height="45" />
                                <h3 className="py-2">Masallacin 🕌 Abu Huraira, Hausari Ward, Maiduguri, Borno State</h3>
                            </Col>

                            <Col className="m-3 px-2 pb-3" lg='11' md="4">
                                <Icon style={{ borderRadius: '5px', background: '#000', width: '50px', height: '40px', padding: '5px' }} icon="line-md:email-twotone" color="white" width="56" height="45" />
                                <h3 className="py-2">masjidabuhurairahhausari@gmail.com</h3>
                            </Col>

                            <Col className="m-3 px-2 pb-3" lg='11' md="3">
                                <Icon icon="gg:phone" color="#000" width="56" height="45" />
                                <h3 className="py-2">0809 424 1538</h3>
                            </Col>

                            <Col className="m-3 px-2 pb-3" lg='11' md="3">
                                <Icon icon="la:box-open" color="#000" width="80" height="55" />
                                <h3 className="py-2">UBA</h3>
                                <h3 className="py-2">2249185593</h3>
                                <h3 className="py-2">Jibwis Abu Huraira Mosque</h3>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <GoToTop />
        </div>
        <Footer />

    </>
}

export default Contact;