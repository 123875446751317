import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import NavBar from "../components/Nav";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { Store } from "../context/store";

function Register() {
  let store = useContext(Store);
  let [mainUrl] = store.url;
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [error, setError] = useState("");
  let navigate = useNavigate();
  let [loading, setLoading] = useState(false);

  let register = async () => {
    setLoading(true);

    const isMissingFields =
      password.trim() === "" || email.trim() === "" || name.trim() === "";

    if (isMissingFields) {
      setError("Please fill missing field(s)!!!");
      clearErrorAndLoading(1500);
      return;
    }

    const url = `${mainUrl}/register`;
    const data = { email, password, name, role: "admin" };

    try {
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      });

      if (response.status === 201) {
        await response.json();
        setLoadingAndClearError(
          "Successfully registered",
          "/login",
          2000,
          3000
        );
      } else {
        const err = await response.json();
        setErrorAndLoading(err.msg, 1500, 3000);
      }
    } catch (error) {
      setErrorAndLoading("Error during registration", 1500, 3000);
    }
  };

  const clearErrorAndLoading = (timeout) => {
    const t1 = setTimeout(() => {
      setError("");
      setLoading(false);
      clearTimeout(t1);
    }, timeout);
  };

  const setLoadingAndClearError = (
    successMessage,
    redirectPath,
    successTimeout,
    redirectTimeout
  ) => {
    const t1 = setTimeout(() => {
      setLoading(false);
      setError(successMessage);
      setPassword("");
      setEmail("");
      clearTimeout(t1);

      const t2 = setTimeout(() => {
        setError("");
        navigate(redirectPath);
        clearTimeout(t2);
      }, redirectTimeout);
    }, successTimeout);
  };

  const setErrorAndLoading = (
    errorMessage,
    loadingTimeout,
    clearErrorTimeout
  ) => {
    setError(errorMessage);

    const t1 = setTimeout(() => {
      setLoading(false);
      clearTimeout(t1);

      const t2 = setTimeout(() => {
        setError("");
        clearTimeout(t2);
      }, clearErrorTimeout);
    }, loadingTimeout);
  };

  return (
    <>
      <NavBar />

      <div className="container-fluid" style={{ marginTop: "100px" }}>
        <div className="container mt-5">
          <Form className="login-form">
            <h1 className="text-center py-3">Register</h1>
            {loading && <p className="text-center error"> {error}</p>}

            <Row className="mb-3">
              <Col lg="12">
                <Form.Group className="m-3" controlId="formGridEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col lg="12">
                <Form.Group className="m-3" controlId="formGridEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col lg="12">
                <Form.Group className="m-3" controlId="formGridPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button variant="secondary" onClick={() => register()}>
              {loading ? "Loading ..." : "Submit"}
            </Button>
            <small>
              <Link to="/login">Already have an account?</Link>
            </small>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Register;
