import React from "react";
import { Icon } from '@iconify/react';

function Footer() {
    return (
        <>
            <div className="container-fluid mt-5">
                <footer className="container d-flex justify-content-center">
                    <p>من سلك طريقا يلتمس فيه علما, سهل الله له به طريقا الى الجنة</p>
                </footer>

                <footer className="container d-flex justify-content-center">
                    <p>Whoever follows a path seeking knowledge, God will make it easy for him to enter Paradise</p>
                </footer>

                <footer className="container d-flex justify-content-center">
                    <a href="https://t.me/masjidabuhurairahhausari" target="_blank">
                        <Icon icon="mingcute:telegram-fill" width="40" height="40" />
                    </a>

                    <a href="https://www.youtube.com/channel/UCtY5vggJFwTfrf3rrt8dJDQ" target="_blank">
                        <Icon icon="simple-icons:youtube" width="40" height="40" />
                    </a>

                    <a href="https://www.tiktok.com/@masjidabuhurairahhausari" target="_blank">
                        <Icon icon="ic:outline-tiktok" width="40" height="40" />
                    </a>

                    <a href="https://www.facebook.com/Masjid-Abu-Huraira-Hausari-109725014941444/" target="_blank">
                        <Icon icon="formkit:facebook" width="40" height="40" />
                    </a>

                    <a href="https://chat.whatsapp.com/DTcngN1GjmVBxz7ns6Rgv5" target="_blank">
                        <Icon icon="ri:whatsapp-fill" width="40" height="40" />
                    </a>
                </footer>

                <footer className="container d-flex justify-content-center mt-3">
                    <p> &copy; Masjid Abu Hurairah 2023</p>
                </footer>
            </div>
        </>
    );
}

export default Footer;
