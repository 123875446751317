import React, { useEffect } from "react";
import GoToTop from "../components/Top";
import Aos from "aos";
import 'aos/dist/aos.css';
import NavBar from "../components/Nav";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";


function NotFound() {
    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, []);
    return <>
        <div className="cenn">
            <div className="cen" style={{ marginTop: "100px" }}>
                <NavBar />
                <div className="row">
                    <div className="col-md-12 notFound" data-aos="slide-left"
                        data-aos-once="false"
                        data-aos-easing="ease-out-sine">
                        <Container className="notFound" style={{ marginTop: '100px', textAlign: 'center' }} data-aos="slide-left" data-aos-once="true" data-aos-easing="ease-in-sine">
                            <h1 >404</h1>
                            <span style={{ color: '#008001' }}>
                            <h2>PAGE NOT FOUND</h2>
                            </span>
                            <p>Meanwhile, you may <Link to='/'>return to homepage</Link> </p>
                        </Container>

                        

                    </div>
                </div>
            </div >
        </div >
        <GoToTop />
    </>
}

export default NotFound;