import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import NavBar from "../components/Nav";
import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Store } from "../context/store";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

function Upload() {
  const [key, setKey] = useState("video");
  const [cookies, removeCookie] = useCookies(["abu-access", "abu-refresh"]);
  let store = useContext(Store);
  let [mainUrl] = store.url;
  let [videoUrl, setVideo] = useState("");
  let [scholar, setScholar] = useState("");
  let [title, setTitle] = useState("");
  let [image, setImage] = useState(null);
  let [audio, setAudio] = useState(null);
  let [category, setCategory] = useState("");
  let [categories, setCategories] = useState([]);
  let [lectures, setLectures] = useState([]);
  let [error, setError] = useState("");
  let navigate = useNavigate();
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    loadLectures();
    loadCategory();
  }, []);

  let loadLectures = () => {
    let url = mainUrl + "/lectures";
    fetch(url)
      .then((e) => e.json())
      .then((res) => {
        setLectures(res);
      });
  };

  let loadCategory = () => {
    let url = mainUrl + "/category";
    fetch(url)
      .then((e) => e.json())
      .then((res) => {
        setCategories(res);
      });
  };

  let uploadVideo = async () => {
    setLoading(true);

    // Split the title, scholar, and videoUrl by commas
    const titlesArray = title.split(",");
    const scholarsArray = scholar.split(",");
    const videoUrlsArray = videoUrl.split(",");

    // Check if any of the arrays are empty
    const isMissingFields =
      category.trim() === "" ||
      titlesArray.some((value) => value.trim() === "") ||
      scholarsArray.some((value) => value.trim() === "") ||
      videoUrlsArray.some((value) => value.trim() === "");

    if (isMissingFields) {
      setError("Please fill missing field(s)!!!");
      clearErrorAndLoading(1500);
      return;
    }

    // Create an array of data objects, each with one set of values
    const dataArray = titlesArray.map((_, index) => ({
      category,
      title: titlesArray[index].trim(),
      scholar: scholarsArray[index].trim(),
      url: videoUrlsArray[index].trim(),
    }));

    // Define the URL for the API
    const url = `${mainUrl}/lecture/video`;

    try {
      // Use Promise.all to send multiple requests in parallel
      const responses = await Promise.all(
        dataArray.map((dataObj) =>
          fetch(url, {
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${cookies["abu-access"]}`,
            },
            method: "POST",
            body: JSON.stringify(dataObj),
          })
        )
      );

      // Check responses for success or failure
      const successResponses = responses.filter(
        (response) => response.status === 200
      );
      const errorResponses = responses.filter(
        (response) => response.status !== 200
      );

      if (successResponses.length === dataArray.length) {
        const res = await Promise.all(
          successResponses.map((response) => response.json())
        );
        clearInput(3000, res.map((r) => r.msg).join(", "));
      } else {
        const err = await Promise.all(
          errorResponses.map((response) => response.json())
        );
        setErrorAndLoading(err.map((e) => e.msg).join(", "), 1500, 3000);
      }
    } catch (error) {
      console.error("Error adding video:", error);
      setErrorAndLoading("An error occurred", 1500, 3000);
    }
  };

  let uploadAudio = async () => {
    if (!audio) {
      clearErrorAndLoading("Please select an audio!", 1500);
      return;
    }

    // Split the title, scholar, and category by commas
    const titlesArray = title.split(",");
    const scholarsArray = scholar.split(",");
    // const categoriesArray = category.split(',');

    // Check if any of the arrays are empty
    const isMissingFields =
      titlesArray.some((value) => value.trim() === "") ||
      scholarsArray.some((value) => value.trim() === "");

    if (isMissingFields) {
      setError("Please fill missing field(s)!!!");
      clearErrorAndLoading(1500);
      return;
    }

    setLoading(true);

    try {
      const url = `${mainUrl}/lecture/audio`;

      // Use Promise.all to send multiple requests in parallel
      const responses = await Promise.all(
        titlesArray.map((_, index) => {
          const formData = new FormData();
          formData.append("url", audio);
          formData.append("scholar", scholarsArray[index].trim());
          formData.append("title", titlesArray[index].trim());
          formData.append("category", category.trim());

          return fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${cookies["abu-access"]}`,
            },
            body: formData,
          });
        })
      );

      // Check responses for success or failure
      const successResponses = responses.filter(
        (response) => response.status === 200
      );
      const errorResponses = responses.filter(
        (response) => response.status !== 200
      );

      if (successResponses.length === titlesArray.length) {
        const res = await Promise.all(
          successResponses.map((response) => response.json())
        );
        clearInput(3000, res.map((r) => r.msg).join(", "));
      } else {
        const err = await Promise.all(
          errorResponses.map((response) => response.json())
        );
        setErrorAndLoading(err.map((e) => e.msg).join(", "), 1500, 3000);
      }
    } catch (error) {
      console.log("Error adding Audio:", error);
      setErrorAndLoading("An error occurred", 1500, 3000);
    }
  };

  let addCategory = async () => {
    setLoading(true);

    const isMissingFields = title.trim() === "";

    if (isMissingFields) {
      setError("Please fill missing field(s)!!!");
      clearErrorAndLoading(1500);
      return;
    }

    const url = `${mainUrl}/category`;
    const data = { title };
    try {
      const response = await fetch(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${cookies["abu-access"]}`,
        },
        method: "POST",
        body: JSON.stringify(data),
      });
      if (response.status === 200) {
        const res = await response.json();
        clearInput(3000, res.msg);
        loadCategory();
      } else {
        let res = await response.json();
        setErrorAndLoading(res.msg, 1500, 3000);
      }
    } catch (error) {
      console.log("Error adding category:", error);
      setErrorAndLoading("An error occurred", 1500, 3000);
    }
  };

  const addGallery = async () => {
    setLoading(true);

    if (!image) {
      clearErrorAndLoading("Please select an image!", 1500);
      return;
    }

    const url = `${mainUrl}/gallery`;
    const formData = new FormData();
    formData.append("url", image);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${cookies["abu-access"]}`,
        },
        body: formData,
      });

      if (response.status === 200) {
        const res = await response.json();
        clearInput(3000, res.msg);
      } else {
        const res = await response.json();
        setErrorAndLoading(res.msg, 1500, 3000);
      }
    } catch (error) {
      console.log(error);
      setErrorAndLoading("An error occurred", 1500, 3000);
    }
  };

  const clearErrorAndLoading = (timeout) => {
    const t1 = setTimeout(() => {
      setError("");
      setLoading(false);
      clearTimeout(t1);
    }, timeout);
  };

  const clearInput = (successTimeout, msg) => {
    setError(msg);
    const t1 = setTimeout(() => {
      setLoading(false);
      setError("");
      setScholar("");
      setTitle("");
      setVideo("");
      clearTimeout(t1);
    }, successTimeout);
  };

  const setErrorAndLoading = (
    errorMessage,
    loadingTimeout,
    clearErrorTimeout
  ) => {
    setError(errorMessage);

    const t1 = setTimeout(() => {
      setLoading(false);
      clearTimeout(t1);

      const t2 = setTimeout(() => {
        setError("");
        clearTimeout(t2);
      }, clearErrorTimeout);
    }, loadingTimeout);
  };

  let logout = async () => {
    const url = `${mainUrl}/logout`;
    const data = { refreshToken: cookies["abu-refresh"] };
    try {
      const response = await fetch(url, {
        method: "DELETE",
        body: JSON.stringify(data),
      });
      if (response.status === 200) {
        removeCookie("abu-access");
        removeCookie("abu-refresh");

        // Clear the cookies directly from document.cookie
        document.cookie =
          "abu-refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie =
          "abu-access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        const res = await response.json();
        clearInput(3000, res.msg);
        navigate("/");
      } else {
        let res = await response.json();
        setErrorAndLoading(res.msg, 1500, 3000);
      }
    } catch (error) {
      setErrorAndLoading("An error occurred", 1500, 3000);
    }
  };

  const deleteLecture = async (lectureId) => {
    const confirmation = window.confirm("Are you sure you want to delete?");
  
    if (confirmation) {
      const url = `${mainUrl}/lecture/${lectureId}`;
  
      try {
        const response = await fetch(url, {
          method: "DELETE",
        });
  
        if (response.status === 200) {
          const res = await response.json();
          loadLectures();
          alert(res.msg); 
        } else {
          const res = await response.json();
          console.error(res.msg); // Handle errors
        }
      } catch (error) {
        console.error("An error occurred", error);
      }
    } else {
      console.log("Deletion cancelled.");
    }
  };
  

  return (
    <>
      <div className="container-fluid" style={{ marginTop: "100px" }}>
        <div className="container mt-5">
          <NavBar />
          <div>
            <Button id="logout" onClick={() => logout()}>
              Logout
            </Button>
          </div>
          <br />
          <br />
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
            justify
          >
            <Tab eventKey="video" title="Video">
              <Form className="login-form">
                {loading && <p className="text-center error"> {error}</p>}
                <Row className="mb-3">
                  <Col lg="12">
                    <Form.Group className="m-3" controlId="formGridPassword">
                      <Form.Control
                        type="text"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="m-3" controlId="formGridEmail">
                      <Form.Control
                        type="text"
                        placeholder="Enter Video URL"
                        value={videoUrl}
                        onChange={(e) => setVideo(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="m-3" controlId="formGridPassword">
                      <Form.Control
                        type="text"
                        placeholder="Name of Scholar"
                        value={scholar}
                        onChange={(e) => setScholar(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Select
                      className="m-3"
                      controlId="formGridPassword"
                      aria-label="select"
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">Category</option>
                      {categories?.map((e, i) => {
                        return (
                          <option
                            style={{ textTransform: "capitalize" }}
                            value={e._id}
                            key={i}
                          >
                            {e.title}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                </Row>

                <Button variant="secondary" onClick={() => uploadVideo()}>
                  {loading ? "Loading ..." : "Submit"}
                </Button>
              </Form>
            </Tab>

            <Tab eventKey="audio" title="Audio">
              <Form className="login-form">
                {loading && <p className="text-center error"> {error}</p>}
                <Row className="mb-3">
                  <Col lg="12">
                    <Form.Group className="m-3" controlId="formGridPassword">
                      <Form.Control
                        type="text"
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="m-3" controlId="formGridPassword">
                      <Form.Control
                        type="text"
                        placeholder="Name of Scholar"
                        value={scholar}
                        onChange={(e) => setScholar(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="12">
                    <Form.Select
                      className="m-3"
                      controlId="formGridPassword"
                      aria-label="select"
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">Category</option>
                      {categories?.map((e, i) => {
                        return (
                          <option
                            style={{ textTransform: "capitalize" }}
                            value={e._id}
                            key={i}
                          >
                            {e.title}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>

                  <Col lg="12">
                    <Form.Group className="m-3" controlId="formGridEmail">
                      <Form.Control
                        multiple
                        type="file"
                        onChange={(e) => setAudio(e.target.files[0])}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Button variant="secondary" onClick={() => uploadAudio()}>
                  {loading ? "Loading ..." : "Submit"}
                </Button>
              </Form>
            </Tab>

            <Tab eventKey="gallery" title="Gallery">
              <Form className="login-form">
                {loading && <p className="text-center error"> {error}</p>}
                <Row className="mb-3">
                  <Col lg="12">
                    <Form.Group className="m-3" controlId="formGridPassword">
                      <Form.Control
                        type="file"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Button variant="secondary" onClick={() => addGallery()}>
                  {loading ? "Loading ..." : "Submit"}
                </Button>
              </Form>
            </Tab>

            <Tab eventKey="category" title="Category">
              <Form className="login-form">
                {loading && <p className="text-center error"> {error}</p>}
                <Row className="mb-3">
                  <Col lg="12">
                    <Form.Group className="m-3" controlId="formGridPassword">
                      <Form.Control
                        type="text"
                        placeholder="Category Name"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Button variant="secondary" onClick={() => addCategory()}>
                  {loading ? "Loading ..." : "Submit"}
                </Button>
              </Form>
            </Tab>

            <Tab eventKey="all" title="All Lectures">
              <div className="row">
                <div className="col-lg-12">
                  <div className="main-box no-header clearfix">
                    <div className="main-box-body clearfix">
                      <div className="table-responsive">
                        <table className="table user-list">
                          <thead>
                            <tr>
                              <th>
                                <span>Title</span>
                              </th>

                              <th>
                                <span>Type</span>
                              </th>

                              <th>
                                <span>Created</span>
                              </th>

                              <th>
                                <span>Action</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {lectures?.map((e, i) => {
                              const timestamp = e.createdAt; // Assuming e.createdAt is your timestamp

                              const dateOnly = new Date(timestamp)
                                .toISOString()
                                .split("T")[0];
                              return (
                                <tr>
                                  <td>
                                    <span className="user-subhead">
                                      {e.title}
                                    </span>
                                  </td>

                                  <td
                                    style={{
                                      width: "10%",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {e.type}
                                  </td>

                                  <td style={{ width: "10%" }}>{dateOnly}</td>

                                  <td style={{ width: "5%" }}>
                                    <Icon
                                      icon="mdi:delete"
                                      color="red"
                                      width="30"
                                      height="30"
                                      onClick={() => deleteLecture(e._id)}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Upload;
