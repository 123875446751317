import React from "react";
import logo from "../assets/images/masjidLogo.jpeg";
import { Link } from "react-router-dom";

function NavBar() {


    return (
        <>
            <div className="fixed-top" style={{ background: "white" }}>
                <nav className="navbar navbar-expand-lg navbar-light" id="mynav">
                    <div className="container">
                        <Link className="navbar-brand" to='/'>
                            <img src={logo} alt="masjid-abu-hurairah" />
                        </Link>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSS">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse ms-5 ps-5" id="navbarSS">
                            <ul className="navbar-nav">
                                <li className="nav-item dropdown">
                                    <div className="nav-link dropdown-toggle" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        lectures
                                    </div>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <Link className="dropdown-item" to="/lectures/audio">
                                            audio
                                        </Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="/lectures/video">
                                            videos
                                        </Link>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to='/gallery'>gallery</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to='/contact'>contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default NavBar;
