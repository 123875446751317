import React, { useState, useEffect, useContext } from "react";
import NavBar from "../components/Nav";
import Footer from "../components/Footer";
import Card from "../components/card";
import { Store } from "../context/store";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import GoToTop from "../components/Top";

function AllLectures() {
  let store = useContext(Store);
  let [mainUrl] = store.url;
  let [lectures, setLectures] = useState([]);
  let [category, setCategory] = useState([]);
  let [, setCatFilter] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    loadLectures();
    loadCategory();
  }, []);

  let loadLectures = () => {
    let url = mainUrl + "/lectures/video";
    fetch(url)
      .then((e) => e.json())
      .then((res) => {
        setLectures(res);
      });
  };

  let loadCategory = () => {
    let url = mainUrl + "/category";
    fetch(url)
      .then((e) => e.json())
      .then((res) => {
        setCategory(res);
      });
  };

  const handleSearch = (e) => {
    const q = e.target.value;
    setSearchQuery(q);
    const filteredResults = lectures.filter((item) =>
      item.title.toLowerCase().includes(q.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  return (
    <>
      <NavBar />

      <div className="container-fluid" style={{ marginTop: "100px" }}>
        <div className="container mt-5">
          <Row className="my-2 pt-4 pb-3 px-3 lectureTopBar justify-content-between">
            <Col className="lectureInput">
              <select
                onChange={(e) => setCatFilter(e.target.value)}
                defaultValue=""
              >
                <option value={""} className="selectOption">
                  All Categories
                </option>
                {category.map((e, i) => {
                  return (
                    <option value={e.title} key={i} className="selectOption">
                      {e.title.toUpperCase()}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col className="lectureInput" lg="8">
              <input
                type="search"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearch}
              />
            </Col>
          </Row>

          {searchResults.length > 0 && searchQuery !== "" && (
            <div className="mt-1 p-2 search">
              {searchResults.map((e, i) => {
                return (
                  <Link to={`/lecture/${e._id}`}>
                    <p key={i}>{e.title}</p>
                  </Link>
                );
              })}
            </div>
          )}

          <div className="row pt-4" style={{ background: "whitesmoke" }}>
            {lectures.length > 0 ? (
              <>
                <div className="col-lg">
                  <div className="row">
                    <h3 className="text-center m-3">Video Lectures</h3>
                    {lectures?.map((e, i) => {
                      return (
                        <div className="col-md-4">
                          <Card
                            location={e.location}
                            image={e.image}
                            title={e.title}
                            scholar={e.scholar}
                            type={e.type}
                            id={e._id}
                            category={e.category?.title}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <MoonLoader
                height="100"
                width="100"
                radius="50"
                color="green"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
              />
            )}
          </div>
        </div>
        <GoToTop />
      </div>
      <Footer />
    </>
  );
}

export default AllLectures;
